import "./style.css";

type HeaderProps = {
  title: string;
  onNewChat: () => void;
};

export default function Header(props: HeaderProps) {
  return (
    <div className="header">
      <div>
        <h5>{props.title}</h5>
        <button onClick={props.onNewChat}>New Chat</button>
      </div>
    </div>
  );
}
