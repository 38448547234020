import React, { useEffect, useRef } from "react";
import "./style.css";
import { ChatMessage } from "../../helpers/OpenAIHelper";
import MessageBubble from "./message-bubble";

type MessageWindowProps = {
  messages: ChatMessage[];
  isTyping: boolean;
};

const MessageWindow = React.forwardRef<HTMLDivElement, MessageWindowProps>(
  ({ messages, isTyping }, ref) => {
    const lastMessageRef = useRef<HTMLDivElement | null>(null);
    const typingIndicatorRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (isTyping && typingIndicatorRef.current) {
        typingIndicatorRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (lastMessageRef.current) {
        lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, [messages, isTyping]);

    return (
      <div className="message-window-container" ref={ref}>
        {messages
          .filter(
            (msg) =>
              msg.role === "user" ||
              (msg.role === "assistant" && msg.function_call === undefined)
          )
          .map((msg, index, filteredMessages) =>
            msg.content !== "" ? (
              <MessageBubble
                key={index}
                message={msg}
                ref={
                  index === filteredMessages.length - 1 ? lastMessageRef : null
                }
              />
            ) : (
              ""
            )
          )}
        {isTyping ? (
          <MessageBubble
            message={{ role: "user", content: "typing" }}
            ref={typingIndicatorRef}
          />
        ) : null}
      </div>
    );
  }
);

export default MessageWindow;
