import React, { useState } from "react";
import "./style.css";
import { ChatMessage } from "../../helpers/OpenAIHelper";

type MessageInputProps = {
  onMessageSent: (message: ChatMessage) => void;
  placeholder: string;
};

export default function MessageInput({
  onMessageSent,
  placeholder,
}: MessageInputProps) {
  const [messageText, setMessage] = useState<string>("");
  return (
    <div className="send-message-container">
      <div style={{ display: "flex !important" }}>
        <div className="message-input-container">
          <textarea
            id="messageBox"
            placeholder={placeholder}
            value={messageText}
            rows={3}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                if (messageText !== "") {
                  //avoid sending blank messaegs
                  e.preventDefault(); //avoid adding new line when enter is pressed to send the message
                  onMessageSent({ role: "user", content: messageText });
                  setMessage("");
                }
              }
            }}
          />
        </div>
        <div>
          <a href=""></a>
        </div>
      </div>
    </div>
  );
}
